import { useContext, useEffect, useState } from "react";
import { Pillar } from "../../components/carePlan/Pillar";
import {
  PatientGroupType,
  PillarDetailProps,
} from "../../lib/interfaces/carePlan";
import nutritionGray from "../../assets/icons/nutritionGray.svg";
import nutritionColor from "../../assets/icons/nutritionColor.svg";
import nutritionColorSmall from "../../assets/icons/nutritionColorSmall.svg";
import mindfullnessGray from "../../assets/icons/mindfullnessGray.svg";
import mindfullnessColor from "../../assets/icons/mindfullnessColor.svg";
import mindfullnessColorSmall from "../../assets/icons/mindfullnessColorSmall.svg";
import movementGray from "../../assets/icons/movementGray.svg";
import movementColor from "../../assets/icons/movementColor.svg";
import movementColorSmall from "../../assets/icons/movementColorSmall.svg";
import sleepGray from "../../assets/icons/sleepGray.svg";
import sleepColor from "../../assets/icons/sleepColor.svg";
import sleepColorSmall from "../../assets/icons/sleepColorSmall.svg";
import { CarePlanProps } from "./CarePlan";
import { useCarePlan } from "../../lib/hooks/useCarePlan";
import { BaseContext } from "../../lib/context/context";
import { Spinner } from "../../components/spinner/Spinner";
import { useParams } from "react-router-dom";
import { logError } from "../../lib/util/logger";

const pillarsDataIcons = [
  {
    pillarName: "Nutrition",
    pillarIndex: 1,
    icons: {
      grayIcon: nutritionGray,
      colorIcon: nutritionColor,
      smallColorIcon: nutritionColorSmall,
    },
  },
  {
    pillarName: "Emotional Health",
    pillarIndex: 2,
    icons: {
      grayIcon: mindfullnessGray,
      colorIcon: mindfullnessColor,
      smallColorIcon: mindfullnessColorSmall,
    },
  },
  {
    pillarName: "Movement & Exercise",
    pillarIndex: 3,
    icons: {
      grayIcon: movementGray,
      colorIcon: movementColor,
      smallColorIcon: movementColorSmall,
    },
  },
  {
    pillarName: "Sleep Hygiene",
    pillarIndex: 4,
    icons: {
      grayIcon: sleepGray,
      colorIcon: sleepColor,
      smallColorIcon: sleepColorSmall,
    },
  },
];

interface PatientRouteParam {
  userId: string;
}

export const CarePlanPillar = ({ patient }: CarePlanProps) => {
  const { userId } = useParams<PatientRouteParam>();
  const { getPillarsPatientGroups, usePatientPillars } = useCarePlan();
  const { setIsPillarPageShow, setSelectedPillarData, setGroupTypeOptions } =
    useContext(BaseContext);

  const controller = new AbortController();

  useEffect(() => {
    return () => controller.abort();
  }, []);

  const { data: pillarListData, isLoading } = usePatientPillars(userId);

  useEffect(() => {
    fetchPillarsPatientGroups();
  }, [patient]);

  const fetchPillarsPatientGroups = () => {
    getPillarsPatientGroups(controller)
      .then((result) => {
        if (Array.isArray(result)) {
          const groupTypeOptionsData = result?.map(
            (item: PatientGroupType) => ({
              name: item.name,
              value: item.id,
            })
          );
          setGroupTypeOptions(groupTypeOptionsData);
        }
      })
      .catch((error) => {
        logError(
          "Error fetching pillar patient groups.",
          { controller },
          error
        );
      });
  };

  const handleAddCarePlans = (item: PillarDetailProps) => {
    setSelectedPillarData({
      icons: item?.icons || {},
      pillarName: item?.pillarName || "",
      pillarId: item?.id || "",
    });
    setIsPillarPageShow(true);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      {pillarListData?.length > 0 &&
        pillarListData?.map((item: PillarDetailProps, index: number) => {
          const iconInfo = pillarsDataIcons[index]?.icons;

          return (
            <Pillar
              key={item.id || index} // Use a unique key
              pillarDetail={{ ...item, icons: iconInfo }}
              isPillarInfoAvailable={!!item.contentData}
              onClick={() => handleAddCarePlans({ ...item, icons: iconInfo })}
              {...(typeof setIsPillarPageShow === "function"
                ? { setIsPillarPageShow }
                : undefined)}
            />
          );
        })}

      <br />
    </>
  );
};
