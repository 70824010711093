import { Option } from "../../../lib/interfaces/input";
import { Button, ButtonGroup } from "../../button/Button";
import { Select } from "../../select/Select";
import { ButtonWrapper, ContentWrapper } from "./Styled";
import styles from "./style.module.css";

interface addCareForm {
  value: Option;
  options: Option[];
  onChange: (option: any) => void;
  onSubmit: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const AddCareForm = ({
  value,
  options,
  onChange,
  onSubmit,
  onCancel,
  isLoading,
}: addCareForm) => {
  return (
    <ContentWrapper>
      <Select
        label="Patient Group Type"
        options={options}
        value={value}
        onChange={onChange}
        width={"100%"}
        className={styles.selectGroupType}
      />

      <ButtonWrapper>
        <ButtonGroup>
          <Button
            onClick={onCancel}
            type="secondary"
            size="small"
            label="Cancel"
          />

          <Button
            onClick={onSubmit}
            type="primary"
            size="small"
            label="Add"
            disabled={!value.value}
            loading={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </ContentWrapper>
  );
};
