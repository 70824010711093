import React from "react";
import { Modal } from "../../modal/Modal";
import PublishUnpublishCarePlan from "../../../containers/carePlan/pillarCarePlan/PublishUnpublishCarePlan";
import styles from "./style.module.css";
import { SelectedPillarTemplateProps } from "../../../lib/interfaces/carePlan";
import { PatientDetailProps } from "../../../lib/interfaces/carePlan";

export enum PublishModalTypes {
  Draft = "Draft",
  Published = "Published",
  Unpublish = "Unpublish",
  Changed = "Changed",
}

interface PublishModalProps {
  isVisible: boolean;
  modalType: PublishModalTypes | undefined;
  onClose: () => void;
  patient: PatientDetailProps;
  carePlanDetail: SelectedPillarTemplateProps | null; // Adjust based on your care plan type
}

const PublishModal: React.FC<PublishModalProps> = ({
  isVisible,
  modalType,
  onClose,
  patient,
  carePlanDetail,
}) => {
  if (!isVisible) {
    return null; // Return null when the modal is not visible
  }

  const getTitle = () => {
    switch (modalType) {
      case PublishModalTypes.Published:
        return "Publish Care Plan";
      case PublishModalTypes.Unpublish:
        return "Unpublish Care Plan";
      default:
        return "Care Plan";
    }
  };

  return (
    isVisible && (
      <Modal
        visible={isVisible}
        title={getTitle()}
        dismissable={false}
        onCloseModal={onClose}
        className={styles.publishModal}
      >
        <PublishUnpublishCarePlan
          modalType={modalType}
          onClose={onClose}
          patient={patient}
          carePlanDetail={carePlanDetail}
        />
      </Modal>
    )
  );
};

export default PublishModal;
