import { useOktaAuth } from "@okta/okta-react";
import {
  fetchVisits,
  fetchVisitsByProviderId,
  bookAppointment,
  deleteAppointment,
  updateAppointment,
  rescheduleAppointment,
  VisitsData,
} from "../apis/visits";
import { Appointment, Visit, defaultVisit } from "../interfaces/visits";
import { CellProps } from "../../components/table/Table";
import { format, isValid, parse } from "date-fns";
import { AlertContext } from "../context/context";
import { useContext } from "react";
import { usePatient } from "./usePatient";
import { useProvider } from "./useProvider";
import { InsuranceVerificationStatusType } from "../models/insuranceVerificaitonStatusType";
import { CreateVisitRequest } from "../apis/types/visit.types";

export const useVisits = () => {
  const { oktaAuth } = useOktaAuth();
  const { getPatientApptInfo } = usePatient();
  const { getProvider } = useProvider();
  const { pushAlert } = useContext(AlertContext);
  const accessToken = oktaAuth.getAccessToken();
  const visitBufferTime: number = 16;

  const getFutureAndPastVisits = async (
    userId?: string,
    controller?: AbortController
  ) => {
    if (userId && accessToken) {
      const _visits = await fetchVisits(userId, controller);
      const allVisits = JSON.parse(JSON.stringify(_visits));
      if (!_visits) {
        pushAlert(
          "Failed to get future and past patients' visit info.",
          "danger"
        );
      }
      if (!_visits)
        return {
          futureVisits: [],
          pastVisits: [],
        };
      //futureVists may contain visits that are today but at a past time, will filter those
      //out and add them to pastVisits
      const currentDateTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      const pastVisitsToday: Visit[] = [];
      const futureVisits: Visit[] = [];
      if (_visits?.future?.length > 0) {
        for (let i = 0; i < _visits?.future?.length; i++) {
          const formattedVisit = {
            ..._visits.future[i],
            dateTime: _visits.future[i]?.dateTime?.split(" ").join("T") || "",
          };

          const visitDateTime = new Date(_visits.future[i].dateTime);
          visitDateTime.setMinutes(
            visitDateTime.getMinutes() + visitBufferTime
          );
          const formattedVisitDateTime = format(
            visitDateTime,
            "yyyy-MM-dd HH:mm:ss"
          );

          if (formattedVisitDateTime < currentDateTime)
            pastVisitsToday.push(formattedVisit);
          else futureVisits.push(formattedVisit);
        }
      }
      const pastVisits: Visit[] = _visits?.past?.map((visit: Visit) => ({
        ...visit,
        dateTime: visit.dateTime?.split(" ").join("T") || "",
      }));
      return {
        futureVisits: futureVisits,
        pastVisits: pastVisitsToday.concat(pastVisits),
        allVisits: allVisits,
      };
    } else
      return {
        futureVisits: [],
        pastVisits: [],
      };
  };

  const getUpcomingAndLastVisit = async (upcomingData: VisitsData) => {
    if (upcomingData) {
      const _visits = upcomingData;
      if (!_visits) {
        return {
          upcomingVisit: defaultVisit,
          pastVisit: defaultVisit,
          status: "error",
        };
      }

      const upcomingVisit: Visit = findClosestFutureDateTime(_visits.future);
      const pastVisit =
        _visits.past.length > 0
          ? {
              ..._visits.past[0],
              id: _visits.past[0].visitId || _visits.past[0].visitIdEHR || "",
              dateTime: _visits.past[0].dateTime
                ? _visits.past[0].dateTime.split(" ").join("T")
                : "",
            }
          : defaultVisit;
      return {
        upcomingVisit: upcomingVisit,
        pastVisit: pastVisit,
        status: "success",
      };
    } else
      return {
        upcomingVisit: defaultVisit,
        pastVisit: defaultVisit,
        status: "error",
      };
  };

  const getUpcomingVisit = (futureVisits: Visit[]) => {
    const currentDateTime = new Date();
    const emptyUpcomingVisit = defaultVisit;
    if (futureVisits.length > 0) {
      const nextVisit = futureVisits.find((visit) => {
        const visitTime = parse(
          visit.dateTime.split("T").join(" "),
          "yyyy-MM-dd HH:mm:ss",
          new Date()
        );
        return visitTime.getTime() > currentDateTime.getTime();
      });
      if (nextVisit) return nextVisit;
    }
    return emptyUpcomingVisit;
  };

  const getFriendlyDate = (unfriendlyDate: string) => {
    const browserFriendlyDate =
      unfriendlyDate.substring(0, 10) + "T" + unfriendlyDate.substring(10 + 1);
    if (!isValid(new Date(browserFriendlyDate))) {
      return "";
    }
    const date = new Date(browserFriendlyDate);
    return format(date, "MM/d/yyyy") + " at " + format(date, "h:mm a");
  };

  const initUpcomingVisits = (
    data: Visit[],
    handleViewVisitClick: (visitId: string) => void
  ) => {
    const updatedUpcomingVisits: CellProps[][] = [];
    data.map((visit) => {
      const row: CellProps[] = [];
      row.push(visit.dateTime !== "" ? getFriendlyDate(visit.dateTime) : "");
      row.push(visit.visitType ?? "");
      row.push(visit.providerName);
      visit?.visitId
        ? row.push({
            label: "View Visit",
            onClick: () => {
              handleViewVisitClick(visit.visitId ?? "");
            },
          })
        : row.push("");
      updatedUpcomingVisits.push(row);
    });
    return updatedUpcomingVisits;
  };

  const initPastVisits = (data: Visit[]) => {
    const updatedPastVisits: CellProps[][] = [];
    data.map((visit) => {
      const row: CellProps[] = [];
      row.push(visit.dateTime !== "" ? getFriendlyDate(visit.dateTime) : "");
      row.push(visit.visitType ?? "");
      row.push(visit.providerName);
      row.push("");
      updatedPastVisits.push(row);
    });
    return updatedPastVisits;
  };

  const getFutureAndPastVisitsTableData = async (
    futureVisits: Visit[],
    pastVisits: Visit[],
    handleViewVisitClick: (visitId: string) => void,
    userId?: string
  ) => {
    let futureVisitsTableData: CellProps[][] = [];
    let pastVisitsTableData: CellProps[][] = [];
    if (accessToken && userId) {
      futureVisitsTableData = initUpcomingVisits(
        futureVisits,
        handleViewVisitClick
      );
      pastVisitsTableData = initPastVisits(pastVisits);
      return {
        futureVisitsTableData: futureVisitsTableData,
        pastVisitsTableData: pastVisitsTableData,
      };
    } else
      return {
        futureVisitsTableData: [],
        pastVisitsTableData: [],
      };
  };

  const getAppointmentInfoFromVisit = async (visit: Visit) => {
    //get patient information for that visit
    const [patient, provider, careCoordinator] = await Promise.all([
      getPatientApptInfo(visit.userId),
      getProvider(visit.providerId),
      visit.checkingInWith ? getProvider(visit.checkingInWith) : undefined,
    ]);
    const v: Appointment = {
      ...visit,
      patient,
      provider,
      careCoordinator,
    };
    return v;
  };

  const getAllAppointments = async (startDate: string, endDate: string) => {
    if (accessToken) {
      const _visits = await fetchVisitsByProviderId(startDate, endDate);
      if (!_visits) {
        pushAlert("Failed to get patient's appoiments info", "danger");
        return [];
      }
      const futureAppointments: Appointment[] = await Promise.all(
        _visits.future.map(getAppointmentInfoFromVisit)
      );
      const pastAppointments: Appointment[] = await Promise.all(
        _visits.past.map(getAppointmentInfoFromVisit)
      );

      const appointments: Appointment[] =
        pastAppointments.concat(futureAppointments);
      return appointments;
    } else return [];
  };

  const getMappedVisit = (visit: Visit) => {
    return {
      ...visit,
      id: visit?.visitId || visit?.visitIdEHR || "",
      //add a 'T' to comply with safari dateTime requirements
      dateTime: visit?.dateTime?.split(" ").join("T") || "",
      patient: {
        ...visit.patient,
        firstName: visit?.patient?.firstName || "Sample",
        lastName: visit?.patient?.lastName || "Patient",
        name: `${visit?.patient?.firstName} ${visit?.patient?.lastName}`,
        photo: visit?.patient?.photo,
        id: visit?.patient?.userId || "",
        title: "Patient",
      },
    };
  };

  const getAllVisits = async (
    startDate: string,
    endDate: string,
    providerId?: string
  ) => {
    if (accessToken) {
      const _visits = await fetchVisitsByProviderId(
        startDate,
        endDate,
        providerId
      );
      if (!_visits) {
        pushAlert("Failed to get all patients' visit info.", "danger");
        return [];
      }
      const futureVisits: Visit[] = _visits.future.map((visit: Visit) =>
        getMappedVisit(visit)
      );
      const pastVisits: Visit[] = _visits.past.map((visit: Visit) =>
        getMappedVisit(visit)
      );
      const appointments: Visit[] = pastVisits.concat(futureVisits);
      return appointments;
    } else return [];
  };

  const getOneVisitData = async (userId: string, clickedVisitId?: string) => {
    if (accessToken) {
      const _oneVisit = await fetchVisits(userId);
      if (!_oneVisit) {
        pushAlert("Failed to get patient's visit info", "danger");
        return defaultVisit;
      }
      const allVisits = _oneVisit.past.concat(_oneVisit.future);

      for (let i = 0; i < allVisits.length; i++) {
        if (allVisits[i].visitId === clickedVisitId) {
          return allVisits[i];
        }
      }
      return defaultVisit;
    }
    return defaultVisit;
  };

  const scheduleAppointment = async (
    appointmentData: CreateVisitRequest,
    visitId?: string
  ) => {
    if (accessToken) {
      if (!visitId) {
        const res = await bookAppointment(appointmentData);
        return res;
      } else {
        const res = await rescheduleAppointment({
          ...appointmentData,
          visitId,
        });
        return res;
      }
    }
  };

  const cancelAppointment = async (visitId: string, userId: string) => {
    if (accessToken) {
      const result = await deleteAppointment(visitId, userId);
      if (result.status === 200) {
        return true;
      } else {
        pushAlert("Failed to cancel appointment", "danger");
        return false;
      }
    }
    return false;
  };

  const updateCareStatus = async (
    visitId: string,
    careGiven?: boolean,
    checkingInWith?: string
  ) => {
    if (accessToken) {
      const result = await updateAppointment(
        visitId,
        careGiven,
        checkingInWith
      );
      return result as Visit | undefined;
      // return result.data as Visit | undefined
    }
    return undefined;
  };

  const getLastVisit = (visits: Visit[]) => {
    const emptyVisit = defaultVisit;

    if (!visits.length) {
      return emptyVisit;
    }

    const lastVisit = visits[0];
    return {
      ...lastVisit,
      id: lastVisit.visitId || lastVisit.visitIdEHR || "",
      dateTime: lastVisit.dateTime?.split(" ").join("T") || "",
    };
  };

  const findClosestFutureDateTime = (dateArray: Visit[]): Visit => {
    const currentDate = new Date();
    let closestDateTimeObj: Visit | undefined = undefined;
    let closestDifference = Infinity;
    const emptyUpcomingVisit: Visit = defaultVisit;
    if (dateArray.length === 0) {
      return emptyUpcomingVisit;
    }
    dateArray.forEach(({ dateTime, ...otherKeys }) => {
      const parsedDateTime = new Date(dateTime);
      const difference = parsedDateTime.getTime() - currentDate.getTime();
      if (difference > 0 && difference < closestDifference) {
        closestDateTimeObj = { dateTime, ...otherKeys };
        closestDifference = difference;
      }
    });
    return closestDateTimeObj || emptyUpcomingVisit;
  };

  const isPreVisitCompleted = (selectedVisit: Visit): boolean => {
    const insuranceStatus = selectedVisit.patient?.insuranceVerificationStatus;
    const isQuestionnairesComplete =
      selectedVisit?.questionnairesComplete ?? false;
    const isInsuranceVerified =
      insuranceStatus === InsuranceVerificationStatusType.VERIFIED ||
      insuranceStatus === InsuranceVerificationStatusType.NO_INSURANCE;
    return isQuestionnairesComplete && isInsuranceVerified;
  };

  return {
    getFutureAndPastVisits,
    getAppointmentInfoFromVisit,
    getUpcomingAndLastVisit,
    getUpcomingVisit,
    getFutureAndPastVisitsTableData,
    getAllAppointments,
    getAllVisits,
    getOneVisitData,
    scheduleAppointment,
    cancelAppointment,
    updateCareStatus,
    getLastVisit,
    findClosestFutureDateTime,
    isPreVisitCompleted,
  };
};
