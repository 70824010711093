import { ConfirmPopupProps } from "../../../lib/interfaces/carePlan";
import { Button, ButtonGroup } from "../../button/Button";
import { ButtonWrapper, ContentWrapper } from "./Styled";

export const ConfirmPopup = ({ onSubmit, onCancel }: ConfirmPopupProps) => {
  return (
    <ContentWrapper>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            onClick={onCancel}
            type="secondary"
            size="small"
            label="Cancel"
          />
          <Button
            onClick={onSubmit}
            type="primary"
            size="small"
            label="Confirm"
          />
        </ButtonGroup>
      </ButtonWrapper>
    </ContentWrapper>
  );
};