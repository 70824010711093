import { createContext } from "react";
import { UserClaims } from "@okta/okta-auth-js";
import { SelectedChat } from "../../pages/messages/Messages";
import { NotificationType } from "../../components/alertBanner/AlertBanner";
import { ProviderInfo } from "../interfaces/user";
import { Client } from "@twilio/conversations";
import { PatientDetails } from "../../pages/patient/PatientResults";
import { Visit } from "../interfaces/visits";
import {
  PillarDetailProps,
  SelectedPillarDataProps,
  SelectedPillarTemplateProps,
} from "../interfaces/carePlan";
import { Option } from "../interfaces/input";

export interface BaseContext {
  twilio: Client | undefined;
  user: UserClaims | undefined;
  userInfo: ProviderInfo | undefined;
  allProviders: ProviderInfo[] | undefined;
  visitsWithProvider: Visit[];
  isPillarPageShow: Boolean;
  openPopoutChat?: (chat: SelectedChat, template?: string) => void;
  closePopoutChat?: (chat: SelectedChat) => void;
  popoutChat?: SelectedChat[];
  searchQuery?: PatientDetails | undefined;
  setSearchQuery?: React.Dispatch<
    React.SetStateAction<PatientDetails | undefined>
  >;
  setIsPillarPageShow: React.Dispatch<React.SetStateAction<Boolean>>;
  pillarData: PillarDetailProps | null;
  setPillarData: (data: PillarDetailProps) => void;
  isPillarEditShow: Boolean;
  setIsPillarEditShow: React.Dispatch<React.SetStateAction<Boolean>>;
  setSelectedPillarData: (data: SelectedPillarDataProps) => void;
  selectedPillarData: SelectedPillarDataProps | null;
  setShowAddNewCarePlanModal: React.Dispatch<React.SetStateAction<boolean>>;
  showAddNewCarePlanModal: boolean;
  setGroupTypeOptions: (data: Option[]) => void;
  groupTypeOptions: Option[];
  setSelectedTemplateData: (data: SelectedPillarTemplateProps) => void;
  selectedTemplateData: SelectedPillarTemplateProps | null;
  setRefetchPillarTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  refetchPillarTemplate: boolean;
}

export const BaseContext = createContext<BaseContext>({
  twilio: undefined,
  user: undefined,
  userInfo: undefined,
  allProviders: undefined,
  visitsWithProvider: [],
  openPopoutChat: undefined,
  closePopoutChat: undefined,
  popoutChat: [],
  searchQuery: undefined,
  isPillarPageShow: false,
  setIsPillarPageShow: () => {},
  pillarData: null,
  setPillarData: () => {},
  isPillarEditShow: false,
  setIsPillarEditShow: () => {},
  setSelectedPillarData: () => {},
  selectedPillarData: null,
  setShowAddNewCarePlanModal: () => {},
  showAddNewCarePlanModal: false,
  setGroupTypeOptions: () => {},
  groupTypeOptions: [],
  setSelectedTemplateData: () => {},
  selectedTemplateData: null,
  setRefetchPillarTemplate: () => {},
  refetchPillarTemplate: false,
});

export interface AlertContext {
  pushAlert: (
    message: string,
    type?: NotificationType,
    closeable?: boolean,
    withIcon?: boolean
  ) => void;
  clearAlerts: () => void;
}

export const AlertContext = createContext<AlertContext>({
  pushAlert: () => undefined,
  clearAlerts: () => undefined,
});
