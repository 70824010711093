import { useOktaAuth } from "@okta/okta-react";
import { useContext } from "react";
import carePlanAPI, { ProgressItem } from "../apis/carePlan";
import { Progress } from "../apis/carePlan";
import { AlertContext, BaseContext } from "../context/context";
import { CarePlan } from "../interfaces/carePlan";
import { useQuery } from "react-query";
import { logError } from "../util/logger";

export interface ChartItem {
  date: Date;
  y: number;
}

export interface returnedData {
  progressData: CarePlanProgress;
  startDate: Date;
  endDate: Date;
}

export interface CarePlanProgress {
  progress: ChartItem[];
  move: ChartItem[];
  nourish: ChartItem[];
  calm: ChartItem[];
}

export interface PillarProps {
  id: number;
  icon?: string;
  iconColor?: string;
  iconColorSmall?: string;
  title?: string;
  status?: string;
  type?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export const useCarePlan = () => {
  const { oktaAuth } = useOktaAuth();
  const { pushAlert } = useContext(AlertContext);
  const { setRefetchPillarTemplate } = useContext(BaseContext);
  const accessToken = oktaAuth.getAccessToken();

  const getCarePlan = async (userId: string, controller?: AbortController) => {
    if (accessToken && userId) {
      const result = await carePlanAPI.fetch(userId, controller);
      if (!result) {
        pushAlert("Failed to get patient's care plan info.", "danger");
      }
      if (!result || result === "cancelled") return undefined;
      if (result.data) {
        const carePlan: any = result.data;
        return carePlan as CarePlan;
      } else return undefined;
    }
    return undefined;
  };

  const getPublishedCarePlan = async (
    userId: string,
    controller?: AbortController
  ) => {
    if (accessToken && userId) {
      const result = await carePlanAPI.fetchPublished(userId, controller);
      if (!result) {
        pushAlert(
          "Failed to get published patient's care plan info.",
          "danger"
        );
      }
      if (!result || result === "cancelled") return undefined;
      if (result.data && result.data.length > 0) {
        const carePlan: any = result.data[0];
        if (carePlan) {
          carePlan.pillars?.move?.actions?.forEach((action: any) => {
            action.category = action.category.toLowerCase();
          });
          carePlan.pillars?.nourish?.actions?.forEach((action: any) => {
            action.category = action.category.toLowerCase();
          });
          carePlan.pillars?.calm?.actions?.forEach((action: any) => {
            action.category = action.category.toLowerCase();
          });
        }
        return carePlan as CarePlan;
      } else return undefined;
    }
    return undefined;
  };

  const postCarePlan = async (
    userId: string,
    carePlan: CarePlan,
    publish?: boolean,
    sendToEpic?: boolean
  ) => {
    if (accessToken) {
      const _carePlan = { ...carePlan, publish, sendToEpic };
      const result = await carePlanAPI.post(userId, _carePlan);
      if (!result) {
        pushAlert("Could not save care plan", "danger");
      } else {
        return result;
      }
    }
  };

  const calculateAllProgress = (
    move: ChartItem[],
    nourish: ChartItem[],
    calm: ChartItem[]
  ) => {
    let allProgress: ChartItem[] = [];
    allProgress = move.map((dataPoint, index) => {
      return {
        date: dataPoint.date,
        y: move[index].y + nourish[index].y + calm[index].y,
      };
    });
    return allProgress;
  };

  const formatProgressItem = (progressItem: ProgressItem) => {
    const date = `${progressItem.normalizedPeriodDate.month} ${progressItem.normalizedPeriodDate.day} ${progressItem.normalizedPeriodDate.year}`;
    return {
      date: new Date(date),
      y: progressItem.score,
    };
  };

  const formatProgressData = (data: Progress): returnedData => {
    const progressData: CarePlanProgress = {
      progress: [],
      move: [],
      nourish: [],
      calm: [],
    };

    progressData.move =
      data.pillars.move?.progress.map(formatProgressItem) || [];
    progressData.nourish =
      data.pillars.nourish?.progress.map(formatProgressItem) || [];
    progressData.calm =
      data.pillars.calm?.progress.map(formatProgressItem) || [];
    //each data point in .progress will be the sum of that data point in move, nourish, and calm
    progressData.progress = calculateAllProgress(
      progressData.move,
      progressData.nourish,
      progressData.calm
    );

    const firstItem = data.pillars.move?.progress[0];
    const lastItem =
      data.pillars.move?.progress[data.pillars.move.progress.length - 1];
    if (firstItem && lastItem) {
      const startDate = new Date(
        `${firstItem.normalizedPeriodDate.month} ${firstItem.normalizedPeriodDate.day} ${firstItem.normalizedPeriodDate.year}`
      );
      const endDate = new Date(
        `${lastItem.normalizedPeriodDate.month} ${lastItem.normalizedPeriodDate.day} ${lastItem.normalizedPeriodDate.year}`
      );
      return {
        progressData: progressData,
        startDate: startDate,
        endDate: endDate,
      };
    } else
      return {
        progressData: progressData,
        startDate: new Date(""),
        endDate: new Date(""),
      };
  };

  const getProgressData = async (
    carePlanId: string,
    pillar: string,
    fromPeriod: string,
    toPeriod: string,
    publishDate?: string,
    controller?: AbortController
  ) => {
    if (accessToken) {
      const result = await carePlanAPI.fetchProgressData(
        carePlanId,
        pillar,
        fromPeriod,
        toPeriod,
        publishDate,
        controller
      );
      if (!result) {
        pushAlert("Failed to get patient's progress data.", "danger");
      }
      if (!result || result === "cancelled") return undefined;

      const progress: Progress = result;
      const progressData: returnedData = formatProgressData(progress);
      return progressData;
    }
    return undefined;
  };

  const usePatientPillars = (userId: string) => {
    return useQuery(
      ["patientPillars", userId],
      () => carePlanAPI.fetchPatientPillars(userId),
      {
        onSuccess: (data) => {
          if (!data || data === "cancelled") {
            pushAlert("Failed to get patient's care plan pillars.", "danger");
          }
        },
        onError: (error) => {
          pushAlert("Failed to get patient's care plan pillars.", "danger");
          logError(
            "Error to get patient's care plan pillars:",
            { userId },
            error as Error
          );
        },
        select: (result) => {
          if (!result || result === "cancelled") {
            return [];
          } else if (result.data) {
            return result.data;
          }
          return [];
        },
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnMount: "always",
      }
    );
  };

  const getPillarsPatientGroups = async (controller?: AbortController) => {
    if (accessToken) {
      const result = await carePlanAPI.fetchPillarsPatientGroups(controller);
      if (!result || result === "cancelled") {
        pushAlert("Failed to get patient's care plan pillars.", "danger");
        return [];
      } else if (result.data) {
        return result.data;
      }
      return [];
    }
    return [];
  };

  const getCarePlanListById = async (
    pillarId?: string,
    groupTypeId?: string,
    controller?: AbortController
  ) => {
    if (accessToken && pillarId) {
      const result = await carePlanAPI.fetchCarePlanListById(
        pillarId,
        groupTypeId,
        controller
      );
      if (result.data) {
        return result.data;
      } else return result;
    }
    return undefined;
  };

  const addCarePlan = async (
    userId: string,
    pillarId: string,
    patientGroupTypeId: string,
    currentData: CarePlan
  ) => {
    if (accessToken) {
      const result = await carePlanAPI.addCarePlan(
        userId,
        pillarId,
        patientGroupTypeId,
        currentData
      );
      if (!result) {
        pushAlert("Could not save care plan", "danger");
      } else {
        return result;
      }
    }
  };

  const updateCarePlan = async (
    userId: string,
    pillarId: string,
    patientGroupTypeId: string,
    careplanId: string,
    content: CarePlan
  ) => {
    if (accessToken) {
      const result = await carePlanAPI.updateCarePlan(
        userId,
        pillarId,
        patientGroupTypeId,
        careplanId,
        content
      );
      if (!result) {
        pushAlert("Could not update care plan", "danger");
      } else {
        return result;
      }
    }
  };

  const addCarePlanAttachment = async (
    userId: string,
    name: string,
    file: string,
    onProgress: (progress: number) => void
  ) => {
    if (accessToken) {
      const result = await carePlanAPI.addCarePlanAttachment(
        userId,
        name,
        file,
        onProgress
      );
      if (!result) {
        pushAlert("Could not save attchment", "danger");
      } else {
        return result;
      }
    }
  };

  const updateCarePlanStatus = async (
    careplanId: string,
    userId: string,
    actionType: string,
    isNotify: number
  ) => {
    if (accessToken) {
      const response = await carePlanAPI.updatePublishUnpublish(
        careplanId,
        userId,
        actionType,
        isNotify
      );
      if (!response) {
        pushAlert("Failed to update care plan status", "danger");
        return;
      }
      return response;
    }
  };

  const usePillarTemplates = (
    userId: string,
    pillarId: string,
    pageNumber: number,
    pageSize: number
  ) => {
    return useQuery(
      ["pillarTemplates", userId],
      () =>
        carePlanAPI.fetchPillarTemplates(
          userId,
          pillarId,
          pageSize,
          pageNumber
        ),
      {
        onSuccess: (data) => {
          if (!data || data === "cancelled") {
            pushAlert("Failed to get pillar templates.", "danger");
          }
          setRefetchPillarTemplate(false);
        },
        onError: (error) => {
          pushAlert("Failed to get pillar templates.", "danger");
          logError(
            "Error to get pillar templates:",
            { userId, pillarId },
            error as Error
          );
        },
        select: (result) => {
          if (!result || result === "cancelled") {
            return [];
          } else if (result.data) {
            return result.data;
          }
          return [];
        },
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnMount: "always",
        enabled: false,
      }
    );
  };

  return {
    getCarePlan,
    getPublishedCarePlan,
    postCarePlan,
    getProgressData,
    formatProgressData,
    getPillarsPatientGroups,
    getCarePlanListById,
    addCarePlan,
    updateCarePlan,
    addCarePlanAttachment,
    updateCarePlanStatus,
    usePatientPillars,
    usePillarTemplates,
  };
};
