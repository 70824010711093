import styled from "styled-components";

export const PillarInfoContainer = styled.div`
  display: flex;
  height: 76px;
  padding: 0px 65px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--v2-color-dropdown-right);
`;

export const PillarInfoHeader = styled.div`
  display: flex;
  background: #e8e8e8;
  justify-content: space-between;
  align-items: center;
  padding: 0px 65px;
  height: 49px;
`;

export const PillarTypeLabel = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 250px;
  font-size: 14px;
  font-weight: 500;
`;

export const PillarInfoUpdate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: start;
  width: 220px;

  span {
    font-size: 14px;
    font-weight: 400;
    padding: 2.5px 0px;
  }

  span:first-child {
    color: var(--color-gray-900);
  }

  span:nth-child(2) {
    color: var(--rv-grey-text);
  }
`;

export const NoCarePlan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  strong {
    font-size: 24px;
    font-weight: 600;
    color: #292524;
    margin-top: 20px;
  }
  span {
    color: #766f6b;
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
  }
`;

export const DropdownMenu = styled.div`
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  z-index: 1;

  & > div {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #292524;
    &:hover {
      background-color: #ecf5f9;
    }
  }
`;
