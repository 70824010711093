import { Button } from "../../button/Button";
import { ChevronRight } from "../../customIcons/CustomIcons";
import { PlusIconGray } from "../../customIcons/CustomIcons";
import { CarePlanStatus } from "../../carePlanStatus/CarePlanStatus";
import { CarePlanPillarProps } from "../../../lib/interfaces/carePlan";
import {
  Cards,
  Icon,
  PillarInfoContainer,
  PillarTypeLabel,
  PillarInfoUpdate,
  Info,
  PillarDetailContainer,
  Blank,
  RightArrowIcon,
} from "./Styled";
import { useContext } from "react";
import { BaseContext } from "../../../lib/context/context";
import { format, subHours } from "date-fns";

export const Pillar = ({
  pillarDetail,
  isPillarInfoAvailable,
  onClick,
  setIsPillarPageShow,
}: CarePlanPillarProps) => {
  const { allProviders, setSelectedPillarData } = useContext(BaseContext);

  function getProviderNameById(providerId: string): string {
    return (
      (allProviders &&
        allProviders?.find((provider) => provider.providerId === providerId)
          ?.name) ??
      ""
    );
  }
  return (
    <Cards>
      {!isPillarInfoAvailable && (
        <Icon>
          <img
            src={
              isPillarInfoAvailable
                ? pillarDetail?.icons?.colorIcon
                : pillarDetail?.icons?.grayIcon
            }
            alt="icon"
          />
        </Icon>
      )}
      <PillarInfoContainer isPillarInfoAvailable={isPillarInfoAvailable}>
        {isPillarInfoAvailable ? (
          <>
            <Icon>
              <img
                src={
                  isPillarInfoAvailable
                    ? pillarDetail?.icons?.colorIcon
                    : pillarDetail?.icons?.grayIcon
                }
                alt="icon"
              />
            </Icon>
            <PillarDetailContainer>
              <PillarTypeLabel>
                <span>{pillarDetail?.pillarName}</span>
                <span>{pillarDetail?.contentData?.patientGroupTypeName}</span>
              </PillarTypeLabel>

              {pillarDetail?.contentData.updatedAt && (
                <PillarInfoUpdate>
                  <span>
                    Updated{" "}
                    {`${format(
                      subHours(
                        new Date(
                          pillarDetail?.contentData.updatedAt
                            .split(" ")
                            .join("T")
                        ),
                        7
                      ),
                      "MMM dd yyyy"
                    )}, ${format(
                      subHours(
                        new Date(
                          pillarDetail?.contentData.updatedAt
                            .split(" ")
                            .join("T")
                        ),
                        7
                      ),
                      "h:mma"
                    ).toLowerCase()}`}
                  </span>
                  {pillarDetail?.contentData.updatedBy && (
                    <span>
                      {getProviderNameById(pillarDetail?.contentData.updatedBy)}
                    </span>
                  )}
                </PillarInfoUpdate>
              )}
              {pillarDetail?.contentData.status !== undefined && (
                <CarePlanStatus status={pillarDetail?.contentData.status} />
              )}

              {isPillarInfoAvailable && setIsPillarPageShow && (
                <RightArrowIcon
                  onClick={() => {
                    setIsPillarPageShow(true);
                    setSelectedPillarData({
                      icons: pillarDetail?.icons || {},
                      pillarName: pillarDetail?.pillarName || "",
                      pillarId: pillarDetail?.id,
                    });
                  }}
                >
                  <ChevronRight />
                </RightArrowIcon>
              )}
            </PillarDetailContainer>
          </>
        ) : (
          <>
            <Info>
              <Button
                size="small"
                type="transparent"
                label={`Add ${pillarDetail?.pillarName}`}
                iconPosition="left"
                Icon={PlusIconGray}
                onClick={onClick}
              />
            </Info>
            <Blank />
          </>
        )}
      </PillarInfoContainer>
    </Cards>
  );
};
